import { BrowserRouter } from 'react-router-dom';

import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import 'tailwindcss/dist/tailwind.min.css';
import GlobalStyles from './styles/global';

import AppProvider from './hooks';
import { DatatableProvider } from './hooks/Datatable';
import Routes from './routes';

// theme
import 'primereact/resources/themes/lara-light-indigo/theme.css';

// core
import 'primereact/resources/primereact.min.css';

function App() {
  return (
    <AppProvider>
      <DatatableProvider>
        <BrowserRouter>
          <Routes />
          <GlobalStyles />
        </BrowserRouter>
      </DatatableProvider>
    </AppProvider>
  );
}

export default App;
