export function debounce(time, callback, ...params) {
  const DEBOUNCE_ID = sessionStorage.getItem('DEBOUNCE_ID');

  if (DEBOUNCE_ID) clearTimeout(DEBOUNCE_ID);

  const DEBOUNCE_CREATED_ID = setTimeout(() => {
    if (callback && typeof callback === 'function') callback(...params);
  }, time);

  sessionStorage.setItem('DEBOUNCE_ID', DEBOUNCE_CREATED_ID);
}
