/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-shadow */
/* eslint-disable react/destructuring-assignment */
import { Form } from '@unform/web';
import axios from 'axios';
import { format, parseISO } from 'date-fns';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';

import { AiOutlineClose } from 'react-icons/ai';
import { FaRegEye } from 'react-icons/fa';
import { useAuth } from '~/hooks/Auth';
import { useValidError } from '~/hooks/ValidError';
import api from '~/services/api';

import Loading from '~/components/Loading';
import MiniProfile from '~/components/MiniProfile';
import { Banner, Container, Modal } from './styles';

import deleteIcon from '~/assets/icons/delete.svg';
import exportIcon from '~/assets/icons/export.svg';
import searchIcon from '~/assets/icons/search.svg';

import banner from '~/assets/banners/banner.png';
import Select from '~/components/Select';
import Table from '~/components/Table/';
import { Search } from '~/components/Table/styles';
import TransferOS from '~/components/TransferOS';
import { useDatatable } from '~/hooks/Datatable';
import { debounce } from '~/utils/debounce';

let cancelToken;

export default function Home() {
  const { validError } = useValidError();
  const { user } = useAuth();
  const history = useHistory();
  const params = useParams();
  const [orders, setOrders] = useState([]);
  const [orderId, setOrderId] = useState('');
  const [finalities, setFinalities] = useState([]);
  const [selectedPage, setSelectedPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [type, setType] = useState('');
  const [showFilter, setShowFilter] = useState(false);
  const [statesSelected, setStatesSelected] = useState([]);
  const [status, setStatus] = useState([]);
  const [states, setStates] = useState([]);
  const [correspondents, setCorrespondents] = useState([]);
  const [pending_with, setPendingWith] = useState(null);
  const [correspondent, setCorrespondent] = useState(1);
  const [finality_id, setFinality_id] = useState('');
  const [status_id, setStatus_id] = useState('');
  const [star_date, setStar_date] = useState('');
  const [end_date, setEnd_date] = useState('');
  const [correspondent_id, setCorrespondent_id] = useState(null);

  const [showTransfer, setShowTransfer] = useState(false);
  const { setPerPage, setTotalRecords, setOrder, setPage } = useDatatable();

  // Variables AUX
  const CURRENCY = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',

    minimumFractionDigits: 0,
  });

  const name = useMemo(() => {
    const nameParts = user.name.split(' ');
    return `${nameParts[0]} ${nameParts[1]}`;
  }, [user.name]);

  const loadOSs = useCallback(
    async ({ page, searchData, per_page }) => {
      if (typeof cancelToken !== typeof undefined) {
        cancelToken.cancel('canceled');
      }

      setLoading(true);

      try {
        cancelToken = axios.CancelToken.source();
        const response = await api.get('correspondents/oss', {
          cancelToken: cancelToken.token,
          params: {
            page: page || 1,
            search: searchData,
            status_id,
            star_date,
            end_date,
            finality_id,
            correspondent_id,
            states: statesSelected,
            per_page: per_page || 10,
            correspondent,
            pending_with,
          },
        });

        if (response.data) {
          const data = response.data.data.map(os => ({
            ...os,
          }));

          setOrders(data);
          setPerPage(per_page || 10);
          setPage(1);
          setTotalRecords(data.length || 0);
          setOrder(data);
          setSelectedPage(response.data.current_page);
          setLoading(false);
        }
      } catch (error) {
        if (error.message !== 'canceled') {
          validError(error);
          setLoading(false);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      finality_id,
      status_id,
      statesSelected,
      correspondent_id,
      correspondent,
      pending_with,
      star_date,
      end_date,
      params.slug,
      validError,
    ],
  );

  useEffect(() => {
    loadOSs({
      searchData: '',
      page: 1,
    });
  }, [loadOSs]);

  useEffect(() => {
    api
      .get('correspondents/finalities')
      .then(async response => {
        const finalitiesData = response.data.map(finality => ({
          id: finality.id,
          value: finality.finalidade,
          selected: false,
        }));

        finalitiesData.unshift({
          id: 0,
          value: 'Todas',
          selected: true,
        });

        const responseStates = await api.get('correspondents/states');
        const correspondentsUsers = (
          (await api.get('correspondents/3/users')).data || []
        ).map(cd => ({
          id: cd.id,
          value: cd.name,
          selected: false,
        }));

        const statesData = responseStates.data.map(state => ({
          id: state.id,
          value: state.sigla,
          selected: false,
        }));

        const responseStatus = await api.get('correspondents/status');

        const statusData = responseStatus.data.map(dataStatus => ({
          id: dataStatus.id,
          value: dataStatus.status,
          selected: false,
        }));

        statusData.unshift({
          id: 0,
          value: 'Todos',
          selected: true,
        });

        setFinalities(finalitiesData);
        setStates(statesData);
        setStatus(statusData);
        setCorrespondents(correspondentsUsers);
      })
      .finally(() => setLoading(false));
  }, []);

  const handleClick = useCallback(
    // eslint-disable-next-line no-shadow
    (id, callback = () => ({}), ...params) => {
      if (orderId === id) {
        setOrderId('');
      } else {
        setOrderId(id);
      }

      if (callback && typeof callback === 'function') callback(...params);
    },
    [orderId],
  );

  const handleShowFilter = useCallback(() => {
    setShowFilter(true);
  }, []);

  const handleCloseFilter = useCallback(() => {
    setShowFilter(false);
  }, []);

  const handleClose = useCallback(() => {
    setOrderId('');
    setShowTransfer(false);
    loadOSs({
      searchData: search,
      page: selectedPage,
    });
  }, [loadOSs, search, selectedPage]);

  const handleShowTransfer = useCallback(value => {
    setType(value);
    setShowTransfer(true);
  }, []);

  const handleClickTransfer = useCallback(() => {
    Swal.fire({
      text: 'Para quem deseja tranferir essa OS?',
      icon: 'warning',
      showCloseButton: true,
      showDenyButton: true,
      confirmButtonColor: '#9ddef2',
      denyButtonColor: '#ffbe21',
      confirmButtonText: 'Transferir Correspondente',
      denyButtonText: `Transferir Construtor`,
      customClass: {
        denyButton: 'color-onyx-bold',
        confirmButton: 'color-onyx-bold',
      },
    }).then(result => {
      if (result.isConfirmed) {
        handleShowTransfer('');
      }
      if (result.isDenied) {
        handleShowTransfer('builders');
      }
    });
  }, [handleShowTransfer]);

  const handleDeletOS = useCallback(
    async osID => {
      try {
        const response = await api.delete(
          `correspondents/oss/${osID >= 1 ? osID : orderId}`,
        );

        if (response.status !== 200)
          throw new Error(`Não foi possivel deletar a OS ${orderId}`);

        Swal.fire({
          icon: 'success',
          text: `OS ${orderId} exluida com sucesso`,
        });

        loadOSs({
          searchData: '',
          page: 1,
        });
      } catch (error) {
        Swal.fire({
          icon: 'error',
          text: 'Não foi possivel exlcuir a OS',
        });
      }
    },
    [loadOSs, orderId],
  );

  const handleColumnId = useCallback(
    row => (
      <div className="flex flex-row items-center gap-2">
        <div
          className={`h-4 w-4 rounded-full ${
            row?.status?.responsavel_id === 2 ? 'bg-blue-400' : 'bg-yellow-500'
          }`}
        />
        <div className="">{row?.id}</div>
      </div>
    ),
    [],
  );

  const handleAccept = useCallback(
    async serviceOrderId => {
      setLoading(true);
      try {
        const response = await api.patch(
          `correspondents/oss/${serviceOrderId}`,
        );
        if (response.data) {
          history.push(
            `${process.env.PUBLIC_URL}/ordem-de-servico/${serviceOrderId}`,
          );
        }
      } catch (error) {
        validError(error);
      } finally {
        setLoading(false);
      }
    },
    [history, validError],
  );

  const handleRowClick = useCallback(
    order => {
      if (user.isCorrespondente) {
        if (user.isCorrespondente.avancado) {
          Swal.fire({
            text: 'Deseja se tornar responsável por essa OS?',
            icon: 'warning',
            showCloseButton: true,
            showCancelButton: true,
            showDenyButton: true,
            confirmButtonColor: '#4DC68C',
            cancelButtonColor: '#F91818',
            denyButtonColor: '#999999',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não',
            denyButtonText: `Acessar OS`,
            customClass: {
              denyButton: 'order-1',
              cancelButton: 'order-2',
              confirmButton: 'order-3',
            },
          }).then(result => {
            if (result.isConfirmed) {
              handleAccept(order.id);
            }
            if (result.isDenied) {
              history.push(
                `${process.env.PUBLIC_URL}/ordem-de-servico/${order.id}`,
              );
            }
          });
        } else {
          Swal.fire({
            text: 'Deseja se tornar responsável por essa OS?',
            icon: 'warning',
            showCloseButton: true,
            showCancelButton: true,
            confirmButtonText: 'Sim',
            confirmButtonColor: '#4DC68C',
            cancelButtonColor: '#F91818',
            cancelButtonText: 'Não',
            reverseButtons: true,
          }).then(result => {
            if (result.isConfirmed) {
              handleAccept(order.id);
            }
          });
        }
      }
    },
    [handleAccept, history, user.isCorrespondente],
  );

  const handleSearch = useCallback(
    event => {
      try {
        const valueParsed = event?.target?.value || '';
        loadOSs({
          searchData: valueParsed,
          page: 1,
        });

        setSearch(valueParsed);
      } catch (error) {
        console.log('handleSearch', error);
      }
    },
    [loadOSs],
  );

  const orderAlphabetic = useCallback(arr => {
    arr.sort((a, b) => {
      const textA = a.value.toUpperCase();
      const textB = b.value.toUpperCase();
      if (textA < textB) {
        return -1;
      }
      if (textA > textB) {
        return 1;
      }
      return 0;
    });

    return arr;
  }, []);

  const handleRemoveSelected = useCallback(
    stateSelected => {
      let newStatesSelected = statesSelected.filter(
        state => state.id !== stateSelected.id,
      );
      newStatesSelected = orderAlphabetic(newStatesSelected);

      let newStates = states.slice();
      newStates.push(stateSelected);
      newStates = orderAlphabetic(newStates);

      setStates(newStates);
      setStatesSelected(newStatesSelected);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [states, statesSelected],
  );

  const handleSubmit = useCallback(
    formData => {
      formData.states = statesSelected;

      if (formData.initial_date) {
        const [initialDay, initialMonth, initialYear] =
          formData.initial_date.split('/');
        formData.initial_date = new Date(
          initialYear,
          initialMonth - 1,
          initialDay,
          0,
          0,
          0,
          0,
        );
      }

      if (formData.end_date) {
        const [endDay, endMonth, endYear] = formData.end_date.split('/');
        formData.end_date = new Date(
          endYear,
          endMonth - 1,
          endDay,
          23,
          59,
          59,
          99,
        );
      }

      loadOSs(formData);

      handleCloseFilter();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleCloseFilter, statesSelected],
  );

  const handleSelected = useCallback(
    stateSelected => {
      let newStates = states.filter(state => state.id !== stateSelected.id);
      newStates = orderAlphabetic(newStates);

      let newStatesSelected = statesSelected.slice();
      newStatesSelected.push(stateSelected);
      newStatesSelected = orderAlphabetic(newStatesSelected);

      setStatesSelected(newStatesSelected);
      setStates(newStates);
    },
    [orderAlphabetic, states, statesSelected],
  );

  const columns = useMemo(
    () => [
      {
        field: 'buttons',
        header: 'Ações',
        sortable: false,
        body: row => (
          <div className="flex row items-center justify-center gap-0">
            <button
              type="button"
              className="borders-none bg-transparent flex items-center justify-center font-medium w-auto h-auto"
              onClick={() =>
                history.push(
                  `${process.env.PUBLIC_URL}/ordem-de-servico/${row.id}`,
                )
              }
              title="Visualizar OS"
            >
              <FaRegEye color="green" size={17} />
            </button>
            <button
              type="button"
              className="btn-export borders-none bg-transparent flex items-center justify-center font-medium w-5 h-auto"
              onClick={() => handleClick(row.id, handleClickTransfer, '')}
              title="Transferir OS"
            >
              <img src={exportIcon} alt="" className="!w-[61px] h-auto" />
            </button>
            <hr className="my-1" />
            <button
              type="button"
              className="w-5 h-auto btn-delete border-none bg-transparent flex items-center justify-center font-medium"
              title="Excluir OS"
              onClick={() => handleClick(row.id, handleDeletOS, row.id)}
            >
              <img src={deleteIcon} alt="" className="!w-[61px] h-auto" />
            </button>
          </div>
        ),
      },
      {
        field: 'ID',
        header: 'ID',
        sortable: false,
        body: handleColumnId,
      },
      {
        field: 'cliente',
        header: 'Cliente',
        sortable: true,
        body: row => (
          <div
            className="flex flex-col gap-1"
            onClick={() => handleRowClick(row)}
          >
            <p className="color-onyx size-4">{row.cliente.nome || ''}</p>
            <i>{row.cliente?.documento || 'documento não disponível'}</i>
          </div>
        ),
      },
      {
        field: 'finalidade',
        header: 'Finalidade',
        sortable: true,
        body: row => (
          <div onClick={() => handleRowClick(row)}>
            <p className="color-onyx mb-3">
              {row.finalidade?.finalidade || ''}
            </p>
          </div>
        ),
      },
      {
        field: 'construtor',
        header: 'Construtor',
        sortable: true,
        body: row => (
          <div
            className="flex flex-col color-onyx"
            onClick={() => handleRowClick(row)}
          >
            <div>{row.construtor?.name || ''}</div>
            <div>{row.construtor?.email || ''}</div>
          </div>
        ),
      },
      {
        field: 'correspondente',
        header: 'Correspondente',
        sortable: true,
        body: row => (
          <div
            className="flex flex-col color-onyx"
            onClick={() => handleRowClick(row)}
          >
            <div>{row.correspondente?.name || ''}</div>
            <div>{row.correspondente?.email || ''}</div>
          </div>
        ),
      },
      {
        field: 'status',
        header: 'Status',
        sortable: true,
        body: row => (
          <div onClick={() => handleRowClick(row)}>
            <p className="color-onyx mb-3">{row.status?.status || ''}</p>
          </div>
        ),
      },
      {
        field: 'banco',
        header: 'Banco',
        sortable: true,
        body: row => (
          <div onClick={() => handleRowClick(row)}>
            <p className="color-onyx mb-3">
              {row?.simulacao?.amortizacao?.banco?.banco || ''}
            </p>
          </div>
        ),
      },
      {
        field: 'valor',
        header: 'Valor',
        sortable: true,
        body: row => (
          <div onClick={() => handleRowClick(row)}>
            <p className="color-onyx mb-3">
              {CURRENCY.format(Number(row.simulacao?.vlrSolicitado) || 0.0)}
            </p>
          </div>
        ),
      },
      {
        field: 'estado',
        header: 'Estado',
        sortable: true,
        body: row => (
          <div onClick={() => handleRowClick(row)}>
            <p className="color-onyx mb-3">{row.terreno?.estado?.nome || ''}</p>
          </div>
        ),
      },
      {
        field: 'created_at',
        header: 'Data de criação',
        sortable: true,
        body: row => (
          <p className="color-onyx" onClick={() => handleRowClick(row)}>
            {format(parseISO(row.created_at), 'dd/MM/yyyy')}
          </p>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      handleClick,
      handleClickTransfer,
      orderId,
      user.isAdmin,
      user.isCorrespondente,
    ],
  );

  return (
    <Container className="xl:container mx-auto">
      <div className="flex flex-wrap">
        <Banner src={banner} className="w-full md:w-9/12 px-7 pt-7 pb-32">
          <h1 className="text-2xl text-white mb-2">Olá, {name}</h1>
          <h2 className="color-android-green text-2xl">Bem-vindo ao harvey</h2>
        </Banner>
        <div className="hidden md:block pl-6 pt-4 w-full md:w-3/12">
          <div className="flex justify-end items-center">
            <MiniProfile />
          </div>
        </div>
      </div>

      <div className="flex flex-col w-full justify-between menu-table mt-5">
        <div className="w-full flex flex-wrap">
          <div className="flex items-center w-full lg:w-6/12 mb-4">
            <Search className="flex px-2 w-8/12">
              <input
                className="w-full"
                placeholder="Procurar"
                onChange={event => debounce(485, handleSearch, event)}
              />
              <img src={searchIcon} alt="Search" className="mx-2" />
            </Search>
            <button
              type="button"
              className="color-android-green font-semibold bg-transparent block w-4/12"
              onClick={handleShowFilter}
            >
              + Filtrar por outros
            </button>
          </div>
        </div>
        <div className="w-full mb-5 divisor order-2" />
      </div>

      <div className="w-full flex flex-row flex-nowrap items-center justify-between">
        <div className="flex-1 flex flex-row items-center justify-start gap-2">
          <button
            className={`
              border-none
              bg-transparent
              mb-1 md:mb-0 md:mr-5
              flex flex-row flex-nowrap
              items-center gap-2 p-4
            hover:bg-gray-50
              ${pending_with === 2 ? 'bg-gray-200' : ''}
              active:bg-gray-100
              rounded-full
            `}
            onClick={() => setPendingWith(a => (a === 2 ? null : 2))}
          >
            <span className="h-4 w-4 rounded-full bg-blue-400 flex" />
            Construtor
          </button>

          <button
            className={`
              border-none
              bg-transparent
              mb-1 md:mb-0 md:mr-5
              flex flex-row flex-nowrap
              items-center gap-2 p-4
            hover:bg-gray-50
              ${pending_with === 1 ? 'bg-gray-200' : ''}
              active:bg-gray-100
              rounded-full
            `}
            onClick={() => setPendingWith(a => (a === 1 ? null : 1))}
          >
            <span className="h-4 w-4 rounded-full bg-yellow-500" />
            Correspondente
          </button>
        </div>
        <div className="flex-1 flex flex-row items-center justify-end gap-2">
          <button
            className={`
              border-none
              bg-transparent
              mb-1 md:mb-0 md:mr-5
              flex flex-row flex-nowrap
              items-center gap-2 p-4
            hover:bg-gray-50
              ${correspondent === 1 ? 'bg-gray-200' : ''}
              active:bg-gray-100
              rounded-full
            `}
            onClick={() => setCorrespondent(a => (a === 1 ? null : 1))}
          >
            <span className="h-4 w-4 rounded-full bg-red-500" />
            Sem correspondente
          </button>
          <button
            className={`
              border-none
              bg-transparent
              mb-1 md:mb-0 md:mr-5
              flex flex-row flex-nowrap
              items-center gap-2 p-4
            hover:bg-gray-50
              ${correspondent === 2 ? 'bg-gray-200' : ''}
              active:bg-gray-100
              rounded-full
            `}
            onClick={() => setCorrespondent(a => (a === 2 ? null : 2))}
          >
            <span className="h-4 w-4 rounded-full bg-green-500" />
            Com correspondente
          </button>
        </div>
      </div>

      <div className="table-style">
        <Table
          data={orders}
          columns={columns}
          onPerPageChange={perPage =>
            loadOSs({
              searchData: '',
              page: 1,
              per_page: perPage,
            })
          }
          onPageChange={page =>
            loadOSs({
              searchData: '',
              page,
            })
          }
        />
      </div>

      {showTransfer && (
        <TransferOS
          type={type}
          osId={orderId}
          show={showTransfer}
          onClose={handleClose}
        />
      )}

      <Modal show={showFilter} onHide={handleCloseFilter}>
        <Form onSubmit={handleSubmit}>
          <Modal.Header className="mb-8">
            <div className="w-full">
              <h3 className="text-2xl color-onyx font-semibold mb-3 text-left">
                Filtrar por outros
              </h3>
              <p className="text-left">
                Caso deseje filtrar por categorias mais especificas, você pode
                escolher por aqui
              </p>
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="flex flex-wrap justify-start px-0 md:px-8">
              <div className="w-full md:px-5 mb-6">
                <label>Finalidade</label>
                <Select
                  name="finality"
                  options={finalities}
                  className="p-3"
                  placeholder="Selecione"
                  onChange={opt => setFinality_id(opt.id)}
                />
              </div>
              <div className="w-full md:w-6/12 md:px-5 mb-4">
                <div className="w-full mb-6">
                  <label>Datas</label>
                  <div className="flex items-center justify-between gap-4">
                    <div
                      className="w-6/12 pr-3 pt-5"
                      style={{ borderBottom: '1px solid #d5d5d5' }}
                    >
                      <input
                        type="date"
                        name="initial_date"
                        onChange={date => setStar_date(date.target.value)}
                        className="w-full h-full bg-transparent "
                      />
                    </div>
                    <div
                      className="w-6/12 pl-3 pt-5"
                      style={{ borderBottom: '1px solid #d5d5d5' }}
                    >
                      <input
                        type="date"
                        name="end_date"
                        onChange={date => setEnd_date(date.target.value)}
                        className="w-full h-full bg-transparent"
                      />
                    </div>
                  </div>
                </div>
                <div className="w-full my-4">
                  <label>Status</label>
                  <Select
                    name="status"
                    options={status}
                    placeholder="Selecione"
                    className="mb-4 p-3"
                    onChange={opt => setStatus_id(opt.id)}
                  />
                </div>
              </div>
              <div className="w-full md:w-6/12 md:px-5 mb-4">
                <label>Estado da OS</label>
                <Select
                  name="states"
                  options={states}
                  placeholder="Selecione"
                  className="mb-4 p-3"
                  onChange={handleSelected}
                  cleanField
                />
                <div className="flex flex-wrap">
                  {statesSelected.map(state => (
                    <div
                      key={state.id}
                      className="flex items-center justify-center state-card mb-2"
                    >
                      <span className="block mr-2">{state.value}</span>
                      <button
                        type="button"
                        onClick={() => handleRemoveSelected(state)}
                      >
                        <AiOutlineClose size={10} color="#9f9f9f" />
                      </button>
                    </div>
                  ))}
                </div>
              </div>
              <div className="w-full md:px-5 mb-6">
                <label>Correspondentes</label>
                <Select
                  name="correspondent_id"
                  options={correspondents}
                  className="p-3"
                  placeholder="Selecione"
                  onChange={opt => setCorrespondent_id(opt.id)}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="flex flex-wrap justify-end">
              <div className="w-full md:w-6/12 flex flex-wrap md:flex-nowrap justify-end">
                <button
                  type="button"
                  onClick={handleCloseFilter}
                  className="mt-2 md:mt-5 w-full md:mr-1 py-2 rounded-full btn-cancel order-2 md:order-1"
                >
                  Cancelar
                </button>
                <button
                  type="submit"
                  className="mt-2 md:mt-5 w-full md:ml-1 py-2 rounded-full btn-submit order-1 md:order-2"
                >
                  Filtrar
                </button>
              </div>
            </div>
          </Modal.Footer>
        </Form>
      </Modal>

      <Loading active={loading} />
    </Container>
  );
}
