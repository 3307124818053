import React, {
  useState,
  useCallback,
  useEffect,
  useLayoutEffect,
} from 'react';
import { format, getDaysInMonth } from 'date-fns';
import DayPicker from 'react-day-picker';
import PropTypes from 'prop-types';
import 'react-day-picker/lib/style.css';

import { Container, Calendar } from './styles';
import InputMask from '../InputMask';

export default function InputDate({ name, value, onChange, className }) {
  const [selectedDate, setSelectedDate] = useState(value || '');
  const [formatedDate, setFormatedDate] = useState(
    value ? format(value, 'dd/MM/yyyy') : '',
  );
  const [currentMonth, setCurrentMonth] = useState(value || '');
  const [active, setActive] = useState(false);

  // useEffect(() => {
  //   onChange(selectedDate);
  // }, [onChange, selectedDate]);

  const handleFocus = useCallback(() => {
    setActive(true);
  }, []);

  const handleBlur = useCallback(() => {
    if (selectedDate) {
      setFormatedDate(format(selectedDate, 'dd/MM/yyyy'));
    } else {
      setFormatedDate('');
    }
    setTimeout(() => {
      setActive(false);
    }, 100);
  }, [selectedDate]);

  useLayoutEffect(() => {
    const element = document.getElementById(`calendar-${name}`);
    if (element) {
      element.addEventListener('focusout', e => {
        const leavingParent = !element.contains(e.relatedTarget);
        if (leavingParent) {
          handleBlur(false);
        }
      });

      element.addEventListener('focusin', () => {
        handleFocus(true);
      });
    }
  }, [handleBlur, handleFocus, name]);

  const handleDateChange = useCallback((day, modifiers) => {
    if (modifiers.available && !modifiers.disabled) {
      setSelectedDate(day);
      setFormatedDate(format(day, 'dd/MM/yyyy'));
      setActive(false);
    }
  }, []);

  const handleMonthChange = useCallback(month => {
    setCurrentMonth(month);
  }, []);

  const handleChange = useCallback(e => {
    if (e.target.value) {
      const [day, month, year] = e.target.value.split('/');
      let [dayData, monthData, yearData] = e.target.value.split('/');

      if (!year) {
        yearData = new Date().getFullYear();
      }

      if (!month) {
        monthData = new Date().getMonth();
      } else if (monthData > 12) {
        monthData = 11;
      } else {
        monthData -= 1;
      }

      if (!day) {
        dayData = new Date().getDate();
      } else {
        const days = getDaysInMonth(year, month);
        if (dayData > days) {
          dayData = days;
        }
      }

      let dateFormated = '';

      if (day) {
        dateFormated = dayData;
      }

      if (month) {
        if (month > 12) {
          dateFormated += `12`;
        } else {
          dateFormated += `${month}`;
        }
      }

      if (year) {
        dateFormated += `/${yearData}`;
      }

      setFormatedDate(dateFormated);

      const date = new Date(yearData, monthData, dayData);
      setCurrentMonth(date);
      setSelectedDate(date);
    } else {
      setFormatedDate('');
      setCurrentMonth('');
      setSelectedDate('');
    }
  }, []);

  return (
    <Container id={`calendar-${name}`} className={className}>
      <InputMask
        kind="datetime"
        options={{
          format: 'DD/MM/YYYY',
        }}
        name={name}
        placeholder="00/00/0000"
        className="border-none date-input"
        onChange={handleChange}
        value={formatedDate}
      />
      <Calendar active={active}>
        <DayPicker
          weekdaysShort={['D', 'S', 'T', 'Q', 'Q', 'S', 'S']}
          months={[
            'Janeiro',
            'Fevereiro',
            'Março',
            'Abril',
            'Maio',
            'Junho',
            'Julho',
            'Agosto',
            'Setembro',
            'Outubro',
            'Novembro',
            'Dezembro',
          ]}
          month={currentMonth}
          // disabledDays={[{ daysOfWeek: [0, 6] }, ...disabledDays]}
          selectedDays={selectedDate}
          modifiers={{
            available: { daysOfWeek: [0, 1, 2, 3, 4, 5, 6] },
          }}
          onDayClick={handleDateChange}
          onMonthChange={handleMonthChange}
        />
      </Calendar>
    </Container>
  );
}

InputDate.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.instanceOf(Date),
  onChange: PropTypes.func,
  className: PropTypes.string,
};

InputDate.defaultProps = {
  value: '',
  onChange: () => {},
  className: '',
};
