import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { BiSearch } from 'react-icons/bi';

import { useValidError } from '~/hooks/ValidError';
import api from '~/services/api';

import CircleName from '~/components/CircleName';
import Loading from '~/components/Loading';
import { debounce } from '~/utils/debounce';
import { ButtonUser, Modal } from './styles';

export default function TransferOS({ osId, type, show, onClose }) {
  const { validError } = useValidError();
  const [users, setUsers] = useState([]);
  const [usersFilter, setUsersFilter] = useState([]);
  const [userSelected, setUserSelected] = useState(0);
  const [showError, setShowError] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleFetchCorrespondents = useCallback((search = '') => {
    setLoading(true);
    api
      .get(`correspondents/${type}?per_page=10&search=${search}`)
      .then(response => {
        if (response.data) {
          const dataUsers = response.data?.data?.map(builder => {
            const nameParts = builder.name.split(' ');
            return {
              id: builder.id,
              initials: `${nameParts[0][0]}${
                nameParts[nameParts.length - 1][0]
              }`.toUpperCase(),
              name: builder.name,
              email: builder.email,
            };
          });
          setUsers(dataUsers);
          setUsersFilter(dataUsers);
        }
      })
      .catch(error => {
        validError(error);
        console.log(error);
      })
      .finally(() => setLoading(false));
  }, []);

  const handleSearch = useCallback(
    e => {
      const filter = e.target.value.toLowerCase();
      handleFetchCorrespondents(filter);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [users],
  );

  const handleSelect = useCallback(
    user_id => {
      if (userSelected === user_id) {
        setUserSelected(0);
      } else {
        setUserSelected(user_id);
      }
    },
    [userSelected],
  );

  const handleSubmit = useCallback(async () => {
    setLoading(true);
    try {
      setShowError(false);
      if (osId > 0 && userSelected > 0) {
        if (type === 'builders') {
          const formData = {
            builder_id: userSelected,
          };
          const response = await api.patch(
            `correspondents/oss/set-builder/${osId}`,
            formData,
          );
          if (response.data) {
            onClose();
          }
        } else {
          const formData = {
            correspondent_id: userSelected,
          };
          const response = await api.patch(
            `correspondents/oss/set-correspondent/${osId}`,
            formData,
          );
          if (response.data) {
            onClose();
          }
        }
      } else {
        setShowError(true);
      }
    } catch (error) {
      validError(error);
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [osId, userSelected]);

  useEffect(() => {
    handleFetchCorrespondents();
  }, [type, validError]);

  return (
    <>
      <Modal show={show} onHide={onClose}>
        <Modal.Header className="mb-8">
          <div className="w-full">
            <h3 className="text-2xl color-onyx font-semibold mb-5 text-left">
              Tranferir OS
            </h3>
            <p className="text-left">
              Selecione para quem deseja transferir a Ordem de Serviço
            </p>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="flex flex-wrap px-0 md:px-8">
            <div className="w-full md:px-5 mb-4">
              <div className="flex justify-end">
                <div className="flex items-center search-user items-center rounded-full pl-3 pr-5">
                  <input
                    type="text"
                    className="mr-2 py-2 bg-transparent"
                    onChange={event => debounce(455, handleSearch, event)}
                    placeholder="Procurar"
                  />{' '}
                  <BiSearch size={20} color="#414142" />
                </div>
              </div>
            </div>
            <div className="w-full box p-3 md:p-5">
              <div className="flex flex-wrap">
                {!loading && usersFilter.length > 0 ? (
                  <>
                    {usersFilter.map(userData => (
                      <div
                        key={userData.id}
                        className="w-full md:w-6/12 md:px-2 my-2"
                      >
                        <ButtonUser
                          type="button"
                          className="btn-user text-left flex items-center w-full px-3 md:px-5 py-3"
                          onClick={() => handleSelect(userData.id)}
                          selected={userData.id === userSelected}
                        >
                          <CircleName
                            name={userData.initials}
                            color="#414142"
                          />
                          <div>
                            <p>{userData.name}</p>
                            <p>{userData.email}</p>
                          </div>
                        </ButtonUser>
                      </div>
                    ))}
                  </>
                ) : (
                  <div className="w-full flex justify-center items-center">
                    <p>Sem usuários no momento</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="mt-4">
          <div className="flex flex-wrap justify-end">
            {showError && (
              <div className="w-full md:w-6/12 px-4 order-0 md:order-0 my-1 md:my-0">
                <small className="error block text-left px-8">
                  Selecione um construtor
                </small>
              </div>
            )}
            <div className="w-full md:w-3/12 px-4 order-1 md:order-0 my-1 md:my-0">
              <button
                type="button"
                className="btn-cancel rounded-full w-full py-2"
                onClick={onClose}
              >
                Cancelar
              </button>
            </div>
            <div className="w-full md:w-3/12 px-4 order-0 md:order-1 my-1 md:my-0">
              <button
                type="button"
                className="btn-submit rounded-full w-full py-2"
                onClick={handleSubmit}
              >
                Transferir
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
      <Loading active={loading} />
    </>
  );
}

TransferOS.propTypes = {
  osId: PropTypes.oneOfType([
    PropTypes.number.isRequired,
    PropTypes.string.isRequired,
  ]).isRequired,
  type: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
