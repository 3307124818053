import { Switch } from 'react-router-dom';

import Route from './Route';

import AutoLogin from '~/pages/AutoLogin';
import Login from '~/pages/Login';

import Dashboard from '~/pages/Dashboard';
import Documents from '~/pages/Documents';
import CreditAnalysisResult from '~/pages/Documents/CreditAnalysisResult';
import Home from '~/pages/Home';
import Measurements from '~/pages/Measurements';
import Messages from '~/pages/Messages';

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={Login} />

      <Route path="/auto-login/:hash" exact component={AutoLogin} />

      <Route path="/home" component={Home} exact isPrivate />
      <Route
        path="/ordem-de-servico/:slug"
        component={Dashboard}
        exact
        isPrivate
        isDashboard
      />
      <Route
        path="/medicoes/:slug"
        component={Measurements}
        exact
        isPrivate
        isDashboard
      />
      <Route
        path="/documentos/resultado-analise-de-credito/:slug"
        component={CreditAnalysisResult}
        exact
        isPrivate
        isDashboard
      />
      <Route
        path="/documentos/:page/:slug"
        component={Documents}
        exact
        isPrivate
        isDashboard
      />
      <Route path="/mensagens" exact component={Messages} isPrivate />
      <Route path="/mensagens/:slug" component={Messages} isPrivate />
    </Switch>
  );
}
